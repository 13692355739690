@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
@font-face {
    font-family: 'MBF';
    src: url("../assets/MBF-Ultra.otf") format('opentype'),
    url("../assets/MBF-Ultra.ttf") format('truetype'),
    url("../assets/MBF-Ultra.woff") format('woff');
    font-display: swap;
}
@font-face {
    font-family: 'Neuropolitical';
    src: url("../assets/NeuropoliticalRg-Regular.otf") format('opentype');
    font-display: swap;
}

@import "vars";

body {
    font-family: $font;
    font-weight: 300;
    font-size: 16px;
    background: $bg-colour;
    color: $primary;

}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
h1, h2, h3, h4 {
    text-transform: uppercase;
}
ol{
    padding-left:1rem;
    li{
        padding-left: 2rem;
    }
}
.list-with-icon{
    list-style: none;
    padding:0;
    font-size: 24px;
    display:flex;
    flex-wrap: wrap;
    @media screen and (max-width:600px) {
        display: block;
    }
    li{
        flex-basis: 50%;
        display: flex;
        gap:1rem;
        padding-bottom: 5px;
        &:hover{
            img{
                filter:brightness(0) invert(1) opacity(.5) drop-shadow(0 0 0 $red) saturate(1000%);
            }
        }
    }
    img{
        position: relative;
        filter: brightness(0) invert(1);
        display: inline-block;
        width: 24px!important;
        height: 24px!important;
        object-fit: contain;
   
        transition: filter .3s ease-in-out;
        
    }
}
.clear-float {
    clear:both;
}

.highlight {
    color: $secondary;
    font-weight: 700;
}
.semibold{
    font-weight: 600;
}

.button {
    width: fit-content;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    padding: 15px clamp(10px, 3.5vw, 35px);
    max-width: 100%;
    color: $secondary;
    background-color: transparent;
    border: 1px solid $secondary;
    box-sizing: border-box;
    transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
    &:hover {
        background-color: $secondary;
        color: $primary;
    }
}

@media only screen and (max-width: 300px){
    .button {
        padding: 10px;
        width: 100%;
    }
}
.plain-link{
    color:inherit;
    text-decoration: none;
}

.text-red{
    color:$red;
}
.text-grey{
    color:$grey;
}

.font-mbf{
    font-family: 'MBF';
}
.font-neuropolitical{
    font-family: 'Neuropolitical';
}